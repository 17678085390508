import React from 'react';

type Props = {}

const CodeOfTheDay = (props: Props) => {
  return (
    <div>CodeOfTheDay</div>
  )
}

export default CodeOfTheDay;